import { PlusOutlined, SendOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Switch,
  Upload,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import storeJS from 'store';

const Upsert = ({ data, form, onSubmitForm, plans }) => (
  <Form
    form={form}
    initialValues={{
      planId: data.planId || null,
      name: data.name || '',
      picture: data.picture || '',
      domains: data.domains || [],
      forceSSO: data.forceSSO || false,
      allowGoogleSSO: data.allowGoogleSSO || false,
      allowMicrosoftSSO: data.allowMicrosoftSSO || false,
      adminEmail: data.adminEmail || '',
      supportEmail: data.supportEmail || '',
      isPilot: data.isPilot || false,
      pilotStartDate: data.pilotStartDate ? moment(data.pilotStartDate) : null,
      pilotStopDate: data.pilotStopDate ? moment(data.pilotStopDate) : null,
      pipeDriveDealLink: data.pipeDriveDealLink || '',
      pipeDriveOrganizationLink: data.pipeDriveOrganizationLink || '',
      isEditionDisabled: data.settings?.isEditionDisabled || false,
      isCourseInvitationRestricted:
        data.settings?.isCourseInvitationRestricted || false,
      wantsAI:
        data.settings?.wantsAI === undefined ? true : data.settings.wantsAI,
      isWarningDisplayed: data.settings?.isWarningDisplayed || false,
      automaticallyJoinedCourseIds:
        data.settings?.automaticallyJoinedCourseIds || [],
    }}
    layout="vertical"
    onFinish={onSubmitForm}
  >
    <Form.Item
      label="Display name of the organization (e.g. Wooclap)."
      name="name"
      rules={[{ required: true }]}
    >
      <Input placeholder="Wooclap" />
    </Form.Item>
    <Form.Item shouldUpdate noStyle>
      {() => (
        <Form.Item
          label="Picture"
          name="picture"
          getValueFromEvent={(e) => e?.file?.response?.data.url || ''}
        >
          <Upload
            name="file"
            accept="image/*"
            action={`${process.env.REACT_APP_API_URL}/api/uploads/`}
            headers={{
              Authorization: `Bearer ${storeJS.get('token')}`,
            }}
            listType="picture-card"
            showUploadList={false}
          >
            {form.getFieldValue('picture') ? (
              <img
                alt=""
                src={form.getFieldValue('picture')}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            ) : (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      )}
    </Form.Item>
    <Form.Item label="Plan associated with the organization." name="planId">
      <Select
        allowClear
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={[
          {
            label: 'Not attached to any plan',
            value: null,
          },
          ...plans.map((plan) => ({
            label: plan.name,
            value: plan.id,
          })),
        ]}
      />
    </Form.Item>
    <Form.Item
      extra={
        <Alert
          banner
          message="You can use * as a joker to accept multiple subdomains at once (e.g. wooclap.com, *.wooclap.com). Do not add a @ in the domain name."
          type="info"
        />
      }
      label="Trusted domain names of the organization that will be used to automatically add new accounts to the organization."
      name="domains"
      rules={[{ required: true }]}
    >
      <Select mode="tags" placeholder="wooclap.com, *.wooclap.com" />
    </Form.Item>
    <Form.Item
      label="Force users of this organization (domain) to authenticate using a SSO."
      name="forceSSO"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Allow users to authenticate with Google when SSO authentication is forced."
      name="allowGoogleSSO"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Allow users to authenticate with Microsoft when SSO authentication is forced."
      name="allowMicrosoftSSO"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Email address of the administrator of the organization (e.g. admin@wooclap.com)"
      name="adminEmail"
    >
      <Input placeholder="admin@wooclap.com" />
    </Form.Item>
    <Form.Item
      label="Email address of the support inside the organization (e.g. help@wooclap.com)"
      name="supportEmail"
    >
      <Input placeholder="help@wooclap.com" />
    </Form.Item>
    <Form.Item
      label="Mark this organization as using a pilot of Wooflash."
      name="isPilot"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item label="Start date of the pilot" name="pilotStartDate">
      <DatePicker />
    </Form.Item>
    <Form.Item label="Stop date of the pilot" name="pilotStopDate">
      <DatePicker />
    </Form.Item>
    <Form.Item label="Link to the PipeDrive deal page" name="pipeDriveDealLink">
      <Input placeholder="https://wooclap-f830e9.pipedrive.com/deal/2715" />
    </Form.Item>
    <Form.Item
      label="Link to the PipeDrive organization page"
      name="pipeDriveOrganizationLink"
    >
      <Input placeholder="https://wooclap-f830e9.pipedrive.com/organization/3965" />
    </Form.Item>
    <Form.Item
      label="is the Warning about the end of the Pilot phase displayed to users of this organization?"
      name="isWarningDisplayed"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Disable edition. The users will not be able to create or upload any content, neither to join any course."
      extra={
        <Alert
          banner
          message=" This feature should be used in addition to the 'Ids of the courses that will be automatically added to the users of this organization'. if not, users won't have access to any resource."
          type="info"
        />
      }
      name="isEditionDisabled"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Enable AI for this organization."
      name="wantsAI"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Restrict invitation. The users will not be able to join a course if he is not from the same organization."
      name="isCourseInvitationRestricted"
      valuePropName="checked"
    >
      <Switch />
    </Form.Item>
    <Form.Item
      label="Ids of the courses that the users of this organization will automatically join after registering."
      name="automaticallyJoinedCourseIds"
      rules={[{ required: false }]}
    >
      <Select
        mode="tags"
        placeholder="deaf11d9-2988-4ce4-a795-f5b6c4769a95, 77edf81e-e686-47d5-a0e4-9f6cbc9d2a8e"
      />
    </Form.Item>
    <Button htmlType="submit" icon={<SendOutlined />} type="primary">
      Submit
    </Button>
  </Form>
);

Upsert.propTypes = {
  data: PropTypes.object,
  form: PropTypes.object.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(PropTypes.object),
};

Upsert.defaultProps = {
  data: {},
  plans: [],
};

export default Upsert;
