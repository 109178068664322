import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import { useQueryPlans } from 'gql/plan';

import Upsert from './Upsert';

const UpsertContainer = ({ handleSubmit, ...props }) => {
  const { data } = useQueryPlans();
  const [form] = Form.useForm();
  const handleSubmitForm = async (values) => {
    const formattedValues = {
      ...values,
      settings: {
        automaticallyJoinedCourseIds: values.automaticallyJoinedCourseIds,
        isEditionDisabled: values.isEditionDisabled,
        isWarningDisplayed: values.isWarningDisplayed,
        isCourseInvitationRestricted: values.isCourseInvitationRestricted,
        wantsAI: values.wantsAI,
      },
    };
    delete formattedValues.automaticallyJoinedCourseIds;
    delete formattedValues.wantsAI;
    delete formattedValues.isEditionDisabled;
    delete formattedValues.isCourseInvitationRestricted;
    delete formattedValues.isWarningDisplayed;
    await handleSubmit(formattedValues);
  };
  return (
    <Upsert
      form={form}
      onSubmitForm={handleSubmitForm}
      plans={data?.plans}
      {...props}
    />
  );
};

UpsertContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default UpsertContainer;
