import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Spin,
  Tag,
} from 'antd';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const Read = ({ data }) =>
  !data ? (
    <Spin />
  ) : (
    <>
      <Row gutter={16}>
        <Col>
          <Link to="/organizations">
            <Button icon={<ArrowLeftOutlined />}>Back</Button>
          </Link>
        </Col>
        <Col flex="auto">
          <h1>{`View an organization - ${data.name}`}</h1>
        </Col>
        <Col>
          <Space>
            <Link to={`/organizations/populate/${data.id}`}>
              <Button icon={<UserAddOutlined />}>Populate</Button>
            </Link>
            <Link to={`/organizations/update/${data.id}`}>
              <Button icon={<EditOutlined />} type="primary">
                Update
              </Button>
            </Link>
            <Link to={`/organizations/delete/${data.id}`}>
              <Button icon={<DeleteOutlined />} type="danger">
                Delete
              </Button>
            </Link>
          </Space>
        </Col>
      </Row>
      <Divider />
      <Descriptions bordered column={1}>
        <Descriptions.Item label="Name">{data.name}</Descriptions.Item>
        <Descriptions.Item label="Picture">
          {data.picture ? (
            <img
              alt=""
              src={data.picture}
              style={{
                maxWidth: 200,
                maxHeight: 200,
              }}
            />
          ) : (
            'No picture'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Domains">
          {data.domains.map((domain, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={index}>{domain}</Tag>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Force SSO authentication?">
          {data.forceSSO ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Allow SSO authentication with Google?">
          {data.allowGoogleSSO ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Allow SSO authentication with Microsoft?">
          {data.allowMicrosoftSSO ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Admin email">
          {data.adminEmail}
        </Descriptions.Item>
        <Descriptions.Item label="Support email">
          {data.supportEmail}
        </Descriptions.Item>
        <Descriptions.Item label="Is pilot?">
          {data.isPilot ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        {data.isPilot && (
          <>
            <Descriptions.Item label="Pilot start date">
              {dayjs(data.pilotStartDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
            <Descriptions.Item label="Pilot stop date">
              {dayjs(data.pilotStopDate).format('YYYY-MM-DD')}
            </Descriptions.Item>
          </>
        )}
        <Descriptions.Item label="PipeDrive info">
          {data.pipeDriveDealLink && (
            <Button href={data.pipeDriveDealLink} target="blank" type="dashed">
              View the deal
            </Button>
          )}
          {data.pipeDriveOrganizationLink && (
            <Button
              href={data.pipeDriveOrganizationLink}
              target="blank"
              type="dashed"
            >
              View the organization
            </Button>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="is the Warning about the end of the Pilot phase displayed to users of this organization?">
          {data.settings.isWarningDisplayed ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="is Edition disabled for users of this organization?">
          {data.settings.isEditionDisabled ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="is AI enabled for this organization?">
          {data.settings.wantsAI ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Restrict invitation to join course only to users in this organization?">
          {data.settings.isCourseInvitationRestricted ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Yes
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              No
            </Tag>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="automatically joined course ids after registering">
          {data.settings.automaticallyJoinedCourseIds &&
            data.settings.automaticallyJoinedCourseIds.map(
              (courseId, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Tag key={index}>{courseId}</Tag>
              ),
            )}
        </Descriptions.Item>
        <Descriptions.Item label="Plan">
          {data.plan ? (
            <Link to={`/plans/read/${data.plan.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {data.plan.name}
              </Button>
            </Link>
          ) : (
            'Not attached to any plan'
          )}
        </Descriptions.Item>
        <Descriptions.Item label="CAS strategies">
          <p>
            <Badge count={data.CASs.length} overflowCount={999999} />
          </p>
          {data.CASs.map((cas) => (
            <Link key={cas.id} to={`/auth/cas/read/${cas.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {cas.name}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="EduGAIN strategies">
          <p>
            <Badge count={data.eduGAINs.length} overflowCount={999999} />
          </p>
          {data.eduGAINs.map((eduGAIN) => (
            <Link key={eduGAIN.id} to={`/auth/edugain/read/${eduGAIN.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {eduGAIN.name}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="SAML strategies">
          <p>
            <Badge count={data.SAMLs.length} overflowCount={999999} />
          </p>
          {data.SAMLs.map((SAML) => (
            <Link key={SAML.id} to={`/auth/saml/read/${SAML.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {SAML.name}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Moodle services">
          <p>
            <Badge count={data.moodles.length} overflowCount={999999} />
          </p>
          {data.moodles.map((moodle) => (
            <Link key={moodle.id} to={`/lms/moodle/read/${moodle.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {moodle.name}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="LTI 1.1 services">
          <p>
            <Badge count={data.ltisv1p1.length} overflowCount={999999} />
          </p>
          {data.ltisv1p1.map((lti) => (
            <Link key={lti.id} to={`/lms/lti/v1p1/read/${lti.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {lti.name}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="LTI 1.3 services">
          <p>
            <Badge count={data.ltisv1p3.length} overflowCount={999999} />
          </p>
          {data.ltisv1p3.map((lti) => (
            <Link key={lti.id} to={`/lms/lti/v1p3/read/${lti.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {lti.name}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="Accounts">
          <p>
            <Badge count={data.accounts.length} overflowCount={999999} />
          </p>
          {data.accounts.map((account) => (
            <Link key={account.id} to={`/accounts/read/${account.id}`}>
              <Button icon={<LinkOutlined />} type="dashed">
                {`${account.firstName} ${account.lastName}`}
              </Button>
            </Link>
          ))}
        </Descriptions.Item>
      </Descriptions>
    </>
  );

Read.propTypes = {
  data: PropTypes.object,
};

Read.defaultProps = {
  data: undefined,
};

export default Read;
